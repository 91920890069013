exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-articles-js": () => import("./../../../src/pages/allArticles.js" /* webpackChunkName: "component---src-pages-all-articles-js" */),
  "component---src-pages-all-news-js": () => import("./../../../src/pages/allNews.js" /* webpackChunkName: "component---src-pages-all-news-js" */),
  "component---src-pages-articles-[slug]-js": () => import("./../../../src/pages/articles/[slug].js" /* webpackChunkName: "component---src-pages-articles-[slug]-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contentful-articles-js": () => import("./../../../src/pages/contentfulArticles.js" /* webpackChunkName: "component---src-pages-contentful-articles-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-news-[slug]-js": () => import("./../../../src/pages/news/[slug].js" /* webpackChunkName: "component---src-pages-news-[slug]-js" */),
  "component---src-pages-news-bloomberg-js": () => import("./../../../src/pages/news/bloomberg.js" /* webpackChunkName: "component---src-pages-news-bloomberg-js" */),
  "component---src-pages-news-ufind-js": () => import("./../../../src/pages/news/ufind.js" /* webpackChunkName: "component---src-pages-news-ufind-js" */),
  "component---src-pages-platform-js": () => import("./../../../src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */),
  "component---src-pages-preview-index-js": () => import("./../../../src/pages/preview/index.js" /* webpackChunkName: "component---src-pages-preview-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-use-cases-assets-js": () => import("./../../../src/pages/use-cases/assets.js" /* webpackChunkName: "component---src-pages-use-cases-assets-js" */),
  "component---src-pages-use-cases-automation-js": () => import("./../../../src/pages/use-cases/automation.js" /* webpackChunkName: "component---src-pages-use-cases-automation-js" */),
  "component---src-pages-use-cases-time-js": () => import("./../../../src/pages/use-cases/time.js" /* webpackChunkName: "component---src-pages-use-cases-time-js" */)
}

